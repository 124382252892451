<template>
  <div class="active-filters-wrapper" v-if="activeFilters.length">
    <div class="active-filters-label">{{ $t('draggableDynamicTable.activeFilters.title') }}</div>

    <div class="active-filters">
      <div v-for="(filter, filter_index) in activeFilters"
           :key="filter_index"
           class="active-filter cursor-pointer no-select"
           @click="removeFilter(filter)">
        <span>{{filter.label}}</span>
        <custom-icon class="ml-2" icon="TIMES" color="dark-gray" width="11px" height="21px"/>
      </div>
    </div>
    <vs-divider/>
  </div>
</template>

<script>
import CustomIcon from '../customIcon/customIcon'

export default {
  name: 'activeFiltersBox',
  components: {CustomIcon},
  model: {
    prop: 'filtersStatus',
    event: 'activeFilters:updated'
  },
  props: {
    filters: {
      type: Array,
      default: () => { return [] }
    },
    filtersStatus: {}
  },
  data () {
    return {
      activeFilters: []
    }
  },
  methods: {
    getActiveFilters () {
      this.activeFilters = []
      const filters = this.filters.map(elm => {
        return elm.field
      })
      Object.keys(this.filtersStatus).forEach((key) => {

        const filter_index = filters.indexOf(key)
        if (filter_index >= 0 && this.filters[filter_index].type === 'checkbox') {
          this.filtersStatus[key].search.forEach(filter => {
            if (filter.show) {
              this.activeFilters.push({
                field: this.filters[filter_index].field,
                label: filter.label,
                value: filter.value,
                show: filter.show
              })
            }
          })
        }
      })
    },
    removeFilter (filter) {
      const filtersStatus = this.filtersStatus
      const filter_index = filtersStatus[filter.field].search.map(elm => { return elm.value }).indexOf(filter.value)
      filtersStatus[filter.field].search[filter_index].show = false
      this.$emit('activeFilters:updated', filtersStatus)
    }
  },
  watch: {
    filtersStatus: {
      handler () {
        this.getActiveFilters()
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
.active-filters-wrapper {

  .active-filters-label {
    font-weight: 500;
  }

  .active-filters {

    .active-filter {
      display: inline-flex;
      align-items: center;
      width: fit-content;
      max-width: 100%;
      overflow: hidden;
      line-height: 40px;
      padding: 0 10px;
      border: 1px solid #cecece;
      border-radius: .5rem;
      margin: 5px 5px 0 0;

      span {
        white-space: nowrap;
        max-width: calc(100% - 15px);
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
</style>

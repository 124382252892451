export default {
  setClubInfo ({commit}, payload) {
    /*getSetting().then(response => {
      let shop = {}

      response.data.forEach(function (item){
        shop[item.name] = item.value
      })

      commit('SET_CLUB_INFO', shop)
    })*/
    let club = ''
    if (payload) {
      club = {
        'club_code': '1',
        'club_name': payload.clubName || '',
        'club_logo': payload.clubLogo || ''
      }
    } else {
      club = {'club_code':'1', 'club_name':'سامانه مدیریت رزرواسیون', 'club_logo':''}
    }
    commit('SET_CLUB_INFO', club)
  }
}

import axios from '@/http/axios'


export function getCharacters (page, filters = [], sorts = []) {
    let queries = ''
    if (page > 1) {
        queries += `page=${page}`
    }

    if (filters.length) {
        if (queries.length > 0) queries += '&'

        queries += filters.join('&')
    }

    if (sorts.length) {
        if (queries.length > 0) queries += '&'

        queries += sorts.join('&')
    }

    if (queries.length > 0) queries = `?${  queries}`

    return axios({
        url: `v1/admin/characters${queries}`,
        method: 'get'
    })
}



export function getCharacterUsersList (page, id) {
    let queries = ''
    if (page > 1) {
        queries += `page=${page}`
    }
    if (queries.length > 0) queries = `?${  queries}`

    return axios({
        url: `v1/admin/characters/${id}/users${queries}`,
        method: 'get'
    })
}

export function getCharactersTrash () {
    return axios({
        url: 'v1/admin/characters?trashed=true',
        method: 'get'
    })
}

export function insertCharacter (payment) {
    return axios({
        url: 'v1/admin/characters',
        data: payment,
        method: 'post'
    })
}

export function insertUserToCharacter (id, payment) {
    return axios({
        url: `v1/admin/characters/${id}/users`,
        data: payment,
        method: 'post'
    })
}


export function editCharacter (id, payment) {
    return axios.patch(`v1/admin/characters/${id}`, payment)
}

export function deleteCharacter (id, force = false) {
    return axios({
        url: `v1/admin/characters/${id}${force ? '/force' : ''}`,
        method: 'delete'
    })
}

export function removeUserCharacter (id, payment) {
    return axios({
        url: `v1/admin/characters/${id}/users`,
        data: payment,
        method: 'delete'
    })
}

export function restoreCharacter (id) {
    return axios({
        url: `v1/admin/characters/${id}/restore`,
        method: 'put'
    })
}

import axios from '@/http/axios'

function makeQuery (page = 1, filters = [], sorts = []) {
  let queries = []

  if (page > 1) queries.push(`page=${page}`)

  if (filters.length) queries = queries.concat(...filters)

  if (sorts.length) queries = queries.concat(...sorts)

  return queries
}

export function getPaymentGateways (page, filters = [], sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${ queries.join('&')}`

  return axios({
    url: `v1/admin/payment-gateways${queries}`,
    method: 'get'
  })
}

export function getPaymentGateway (id) {

  return axios({
    url: `v1/admin/payment-gateways/${id}`,
    method: 'get'
  })
}
export function getPaymentGatewaysPrice (filters = []) {
  let queries = makeQuery(0, filters)

  if (queries.length > 0) queries = `?${  queries.join('&')}`

  return axios({
    url: `v1/admin/total-payment-gateways${queries}`,
    method: 'get'
  })
}

export function getPaymentGatewayProviders () {

  return axios({
    url: 'v1/admin/payment-gateway-providers',
    method: 'get'
  })
}

export function getPaymentGatewayBalance (id) {

  return axios({
    url: `v1/admin/payment-gateways/${id}/receive`,
    method: 'get'
  })
}

export function receivePaymentGatewayBalance (id, receive) {

  return axios({
    url: `v1/admin/payment-gateways/${id}/receive`,
    data: receive,
    method: 'post'
  })
}

export function getPaymentGatewayTransactions (id, page, filters = [], sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${ queries.join('&')}`

  return axios({
    url: `v1/admin/payment-gateways/${id}/transactions${queries}`,
    method: 'get'
  })
}

export function printPaymentGatewayTransactions (id, filters = [], sorts = []) {
  let queries = []

  if (filters.length) queries = queries.concat(...filters)

  if (sorts.length) queries = queries.concat(...sorts)

  if (queries.length > 0) queries = `&${  queries.join('&')}`

  return axios({
    url: `v1/admin/payment-gateways/${id}/transactions?export=pdf${queries}`,
    method: 'get',
    responseType: 'blob',
  })
}

export function insertPaymentGateway (paymentGateway) {

  return axios({
    url: 'v1/admin/payment-gateways',
    data: paymentGateway,
    method: 'post'
  })
}

export function editPaymentGateways (id, paymentGateway) {

  return axios.patch(`v1/admin/payment-gateways/${id}`, paymentGateway)
}

export function deletePaymentGateways (id, force = false) {

  return axios({
    url: `v1/admin/payment-gateways/${id}${force ? '/force' : ''}`,
    method: 'delete'
  })
}

export function restorePaymentGateways (id) {

  return axios({
    url: `v1/admin/payment-gateways/${id}/restore`,
    method: 'put'
  })
}

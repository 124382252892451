import axios from '@/http/axios'

function makeQuery (page = 1, filters = [], sorts = []) {
  let queries = []

  if (page > 1) queries.push(`page=${page}`)

  if (filters.length) queries = queries.concat(...filters)

  if (sorts.length) queries = queries.concat(...sorts)

  return queries
}

export function getCoaches (page, filters = [], sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${  queries.join('&')}`

  return axios({
    url: `v1/admin/coaches${queries}`,
    method: 'get'
  })
}

export function getActiveCoaches (payload, page, filters = [], sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${  queries.join('&')}`

  return axios({
    url: `v1/admin/active-coaches${queries}`,
    data: payload,
    method: 'post'
  })
}

export function getCoach (id) {

  return axios({
    url: `v1/admin/coaches/${id}`,
    method: 'get'
  })
}

export function getCoachBalance (id) {

  return axios({
    url: `v1/admin/coaches/${id}/receive`,
    method: 'get'
  })
}

export function insertCoach (payload) {

  return axios({
    url: 'v1/admin/coaches',
    data: payload,
    method: 'post'
  })
}

export function editCoach (id, payload) {

  return axios.patch(`v1/admin/coaches/${id}`, payload)
}

export function receiveCoachBalance (id, payload) {

  return axios({
    url: `v1/admin/coaches/${id}/receive`,
    data: payload,
    method: 'post'
  })
}

export function deleteCoach (id, force = false) {

  return axios({
    url: `v1/admin/coaches/${id}${force ? '/force' : ''}`,
    method: 'delete'
  })
}

export function getCoachTransaction (coachId, page, filters = [], sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${  queries.join('&')}`

  return axios({
    url: `v1/admin/coaches/${coachId}/transactions${queries}`,
    method: 'get'
  })
}

export function printCoachTransactions (coachId, filters = [], sorts = []) {
  let queries = []

  if (filters.length) queries = queries.concat(...filters)

  if (sorts.length) queries = queries.concat(...sorts)

  if (queries.length > 0) queries = `&${  queries.join('&')}`

  return axios({
    url: `v1/admin/coaches/${coachId}/transactions?export=pdf${queries}`,
    method: 'get',
    responseType: 'blob',
  })
}

export function getCoachSaleInvoices (id, page, filters = [], sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${  queries.join('&')}`

  return axios({
    url: `v1/admin/coaches/${id}/classes${queries}`,
    method: 'get'
  })
}
export function getCoachSaleInvoicesPrice (id, filters = []) {
  let queries = makeQuery(0, filters)

  if (queries.length > 0) queries = `?${  queries.join('&')}`

  return axios({
    url: `v1/admin/coaches/${id}/total-classes${queries}`,
    method: 'get'
  })
}

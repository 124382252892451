<template>
  <div class="input-box" :class="getClasses">
    <template v-if="type === 'date'">
      <date-picker :locale="$i18n.locale"
                   :locale-config="localeConfig"
                   v-model="value"/>
    </template>
    <input type="text"
           :id="inputId.toString()"
           :class="[getClasses, currency ? 'has-currency' : '']"
           @focus="$emit('focus')"
           @input="value = $event.target.value, refactorValueType()"
           @keyup.enter="value = $event.target.value, refactorValueType(), $emit('enter:keyUp', value)"
           v-model="value"
           style="background: transparent !important;">

    <span v-if="currency && value.length > 0" class="currency" @click="handleFocusInput">{{ currency.hasOwnProperty('label') ? currency.label : currency.hasOwnProperty('value') ? currency.value : currency }}</span>

    <vs-icon
      v-if="returnValue && value !== firstValue"
      class="undo-button"
      icon="replay"
      style="font-size: 17px;"
      @click="value = firstValue, $emit('input', value)"/>
  </div>
</template>

<script>
  import {addComma} from '@/assets/js/functions'
  import DatePicker from 'vue-persian-datetime-picker'

  export default {
    name: 'InputBox',
    model: {
      prop: 'initialValue',
      event: 'input'
    },
    props: {
      initialValue: '',
      align: {
        type: String,
        default: 'left'
      },
      type: {
        type: String,
        default: ''
      },
      currency: '',
      returnValue: {
        type: Boolean,
        default: true
      }
    },
    components: {DatePicker},
    data() {
      return {
        value: '',
        firstValue: '',
        inputId: '',
        localeConfig: {
          fa: {
            dir: 'rtl',
            displayFormat: this.$validator('moment.date'),
            lang: {
              label: 'FA',
              submit: this.$t('datePicker.labels.submit'),
              cancel: this.$t('datePicker.labels.cancel'),
              now: this.$t('datePicker.labels.now'),
              nextMonth: this.$t('datePicker.labels.nextMonth'),
              prevMonth: this.$t('datePicker.labels.prevMonth')
            }
          },
          ar: {
            dow: 0,
            dir: 'rtl',
            displayFormat: vm => {
              // vm.type = date | time | datetime | year | month | yearmonth
              switch (vm.type) {
                case 'date':
                  return this.$validator('moment.date')
                case 'datetime':
                  return this.$validator('moment.dateTime')
                case 'year':
                  return this.$validator('moment.year')
                case 'month':
                  return this.$validator('moment.month')
                case 'yearmonth':
                  return this.$validator('moment.yearMonth')
                case 'time':
                  return this.$validator('moment.time')
              }
            },
            lang: {
              label: 'AR',
              submit: this.$t('datePicker.labels.submit'),
              cancel: this.$t('datePicker.labels.cancel'),
              now: this.$t('datePicker.labels.now'),
              nextMonth: this.$t('datePicker.labels.nextMonth'),
              prevMonth: this.$t('datePicker.labels.prevMonth')
            }
          }
        },
      }
    },
    computed: {
      getClasses() {
        const classes = []
        switch (this.type) {
          case 'price':
          case 'number':
            classes.push('price-style')
            if (!this.align) {
              classes.push('text-right')
            }
            break
        }

        if (this.align) {
          classes.push(`text-${this.align}`)
        }

        if (this.returnValue) {
          classes.push('return-value')
        }
        return classes
      }
    },
    created() {
      if (this.initialValue) {
        this.value = this.initialValue
        this.firstValue = this.initialValue
      }
      this.inputId = Math.random()
    },
    methods: {
      refactorValueType() {
        if (this.type) {
          switch (this.type) {
            case 'price':
              this.value = addComma(this.value)
              break
          }
        } else {
          switch (typeof this.initialValue) {
            case 'number':
              this.value = parseInt(this.value.replace(/\/r/g, '/')) || 0
              break
          }
        }
        this.$emit('input', this.value)
      },
      handleFocusInput() {
        window.document.getElementById(this.inputId.toString()).focus()
      },
      updateFirstValue() {
        this.firstValue = this.value
      }
    },
    watch: {
      initialValue: {
        handler() {
          this.value = this.initialValue
          // this.firstValue = this.initialValue
        },
        deep: true
      }
    }
  }
</script>

<style lang="scss">
  .input-box {
    width: 100%;

    .vpd-input-group {
      width: 20px !important;
      position: absolute;
      top: calc(100% / 2 - 15px);
      left: 2px;

      .vpd-icon-btn {
        opacity: 1 !important;
        height: 25px !important;
        width: 20px !important;
        background-color: inherit !important;

        svg {
          fill: #000000;
          overflow: visible !important;
        }
      }

      .form-control {
        border: 0;
        width: 100%;
        display: none;
        line-height: 35px;
      }
    }

    input {
      height: 100%;
      direction: ltr;
      text-align: left;
      border: none;
      width: 100%;
      transition: border 0.6s cubic-bezier(.48, .24, .36, .99);

      &.return-value {
        padding-right: 30px;
      }

      &.has-currency {
        text-align: right !important;
        padding-right: 75px;
      }

      /*&:focus {
        border-bottom: 1px solid #000;
      }*/
    }

    .currency {
      position: absolute;
      right: 38px;
      z-index: 100;
    }

    .undo-button {
      position: absolute;
      right: 10px;
      top: 0;
      bottom: 0;
      margin: auto;
      height: fit-content;
      cursor: pointer;
      color: #3891bc;
    }
  }

</style>

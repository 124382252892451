<template>
  <div class="custom-input custom-price-input">
    <label :class="[{'is-not-valid': !price.isValid, 'disabled': disabled}, classes || {}]" :style="getStyles">
      <span class="input-label" :class="{'is-focus': price.value.toString().length || label.length}">{{ labelPlaceholder || label }}</span>
      <input type="text"
             id="priceInput"
             :class="[{'disabled': disabled}]"
             :style="inputStyle"
             inputmode="numeric"
             @keydown.enter="handleKeydownEnter"
             @input="handleInputValue"
             :placeholder="placeholder || value.placeholder || ''"
             :disabled="disabled"
             v-model="price.value">
      <span :style="inputStyle">{{ $t('currency') }}</span>
    </label>
    <button v-if="price.value.length > 0 && price.value !== '0' && !disabled" class="clear-btn" @click="handleClearInput">
      <custom-icon icon="TIMES" color="danger" height="20px" width="20px" />
    </button>
  </div>
</template>

<script>
import {addComma} from '@/assets/js/functions'
import CustomIcon from "../customIcon/customIcon";

export default {
  name: 'customPriceInput',
  components: {CustomIcon},
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    labelPlaceholder: {
      type: String,
      default: ''
    },
    isEmpty: {
      type: Boolean,
      default: true
    },
    autoFocus: {
      type: Boolean,
      default: false
    },
    value: {
      type: Object,
      default () {
        return {}
      }
    },
    classes: {
      type: Object,
      default () {
        return {}
      }
    },
    styles: {
      type: Object,
      default () {
        return {}
      }
    },
    inputStyle: {
      type: String,
      default () {
        return ''
      }
    }
  },
  data () {
    return {
      price: {
        value: '',
        isValid: false
      }
    }
  },
  computed: {
    getStyles () {
      const styles = JSON.parse(JSON.stringify(this.styles))
      if (!styles.width) {
        // styles.width = '230px'
      }

      return styles
    }
  },
  created () {
    if (Object.keys(this.value).length) {
      this.initValues()
    }
    this.validateValue()

    setTimeout(() => {
      if (this.autoFocus) {
        let priceInput = window.document.getElementById('priceInput')
        if (priceInput) {
          priceInput.focus()
        }
      }
    }, 100)

  },
  methods: {
    handleInputValue () {
      this.addComma()
      this.validateValue()
      this.$emit('input', {value: this.price.value.split(',').join(''), placeholder: this.value.placeholder, isValid: this.price.isValid})
    },
    handleClearInput () {
      // this.price.value = ''
      this.$emit('input', {value: '0', placeholder: this.value.placeholder, isValid: this.price.isValid})
    },
    validateValue () {
      if (this.isEmpty) {
        this.price.isValid = true
      } else {
        this.price.isValid = parseInt(this.price.value || '0') > 0
      }
    },
    handleKeydownEnter () {
      this.$emit('pressEnter')
    },
    addComma () {
      this.price.value = addComma(this.price.value)
    },
    initValues () {
      this.price.value = addComma(this.value.value) || ''
      this.validateValue()
    }
  },
  watch: {
    value: {
      handler () {
        this.initValues()
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
.custom-price-input {
  position: relative;
  margin: 15px 0;

  label {
    display: block;
    padding: 6px 10px 6px 5px;
    border: 1px solid #cecece;
    border-radius: .5rem;
    backdrop-filter: sepia(1);
    transition: all .3s ease;

    &.is-not-valid {
      border-color: #b00000;
      color: #b00000;

      input {
        color: #b00000;
      }
    }

    span {
      position: absolute;
      padding: 0 5px;
      -webkit-transition: all .3s ease;
      -moz-transition: all .3s ease;
      -o-transition: all .3s ease;
      -ms-transition: all .3s ease;
      transition: all .3s ease;
      top: 7px;

      &.is-focus {
        font-size: 12px;
        background: #ffffff;
        top: -10px;
      }
    }

    input {
      width: calc(100% - 40px);
      background: transparent;
      border: none;
      outline: none !important;
      direction: rtl;

      &.disabled {
        opacity: 0.75;
      }
    }
  }

  .clear-btn {
    position: absolute;
    top: calc(100% / 2 - 10px);
    left: 5px;
    height: 20px;
    width: 20px;
    border: 0;
    background-color: inherit;

    &:hover {
      cursor: pointer;
    }

    .icon-component {
      position: relative;
      right: 5px;
      top: -2px;
    }
  }
}
</style>

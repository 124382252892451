import axios from '@/http/axios'

function makeQuery (page = 1, filters = [], sorts = []) {
  let queries = []

  if (page > 1) queries.push(`page=${page}`)

  if (filters.length) queries = queries.concat(...filters)

  if (sorts.length) queries = queries.concat(...sorts)

  return queries
}

export function getCashBoxes (page = 1, filters = [], sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  queries = queries.length > 0 ? `?${  queries.join('&')}` : ''

  return axios({
    url: `v1/admin/cashes${queries}`,
    method: 'get'
  })
}

export function getCashBoxesTrash (page = 1, filters = [], sorts = []) {

  let queries = makeQuery(page, filters, sorts)

  queries = queries.length > 0 ? `&${  queries.join('&')}` : ''

  return axios({
    url: `v1/admin/cashes?trashed=true${queries}`,
    method: 'get'
  })
}

export function getCashBox (id) {
  return axios({
    url: `v1/admin/cashes/${id}`,
    method: 'get'
  })
}

export function getCashBoxTransactions (id, page = 1, filters = [], sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${  queries.join('&')}`

  return axios({
    url: `v1/admin/cashes/${id}/transactions${queries}`,
    method: 'get'
  })
}

export function printCashBoxTransactions (id, filters = [], sorts = []) {
  let queries = []

  if (filters.length) queries = queries.concat(...filters)

  if (sorts.length) queries = queries.concat(...sorts)

  if (queries.length > 0) queries = `&${  queries.join('&')}`

  return axios({
    url: `v1/admin/cashes/${id}/transactions?export=pdf${queries}`,
    method: 'get',
    responseType: 'blob',
  })
}

export function getCashBoxesPrice (filters = []) {
  let queries = makeQuery(0, filters)

  if (queries.length > 0) queries = `?${  queries.join('&')}`

  return axios({
    url: `v1/admin/total-cashes${queries}`,
    method: 'get'
  })
}

export function insertCashBoxes (payment) {
  return axios({
    url: 'v1/admin/cashes',
    data: payment,
    method: 'post'
  })
}

export function editCashBoxes (payment) {
  return axios.patch(`v1/admin/cashes/${payment.id}`, payment)
}

export function restoreCashBoxes (id) {
  return axios({
    url: `v1/admin/cashes/${id}/restore`,
    method: 'put'
  })
}

export function deleteCashBoxes (id, force = false) {
  return axios({
    url: `v1/admin/cashes/${id}${force ? '/force' : ''}`,
    method: 'delete'
  })
}

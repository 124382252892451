import axios from '@/http/axios'


export function getTimes (page, filters = [], sorts = []) {
    let queries = ''
    if (page > 1) {
        queries += `page=${page}`
    }

    if (filters.length) {
        if (queries.length > 0) queries += '&'

        queries += filters.join('&')
    }

    if (sorts.length) {
        if (queries.length > 0) queries += '&'

        queries += sorts.join('&')
    }

    if (queries.length > 0) queries = `?${  queries}`

    return axios({
        url: `v1/admin/times${queries}`,
        method: 'get'
    })
}


export function generateTime (force = false) {
    return axios({
        url: `v1/admin/times/generate${force ? '/force' : ''}`,
        method: 'post'
    })
}

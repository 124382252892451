import axios from '@/http/axios'

export function getAccounts () {
  return axios({
    url: 'v1/admin/accounts',
    method: 'get'
  })
}

export function insertAccount (payload) {
  return axios({
    url: 'v1/admin/accounts',
    data: payload,
    method: 'post'
  })
}

export function editAccount (id, payload) {
  return axios.patch(`v1/admin/accounts/${id}`, payload)
}

export function deleteAccount (id) {
  return axios({
    url: `v1/admin/accounts/${id}`,
    method: 'delete'
  })
}

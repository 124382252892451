/*=========================================================================================
  File Name: moduleAuthState.js
  Description: Auth Module State
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  eventsChanged: false,
  categoriesChanged: false,
  attributesChanged: false,
  attributeValuesChanged: false,
  productsChanged: false,
  accountsChanged: false,
  documentsChanged: false,
  usersChanged: false,
  userAddressesChanged: {},
  rolesChanged: false,
  paymentGatewaysChanged: false,
  openingBalanceLocked: false,
  openingBalanceChanged: false,
  openingBalanceDeleted: false,
  wooreceiverProductsChanged: false,
  wooreceiverPaymentGatewaysChanged: false,
  wooreceiverUsersChanged: false,
  errorOccurred: false,
  requestStatus: [],
  calendarDate: '',
  currentTime: ''
}

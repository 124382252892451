export default {
  // navMenuItems Words
  dashboard: 'Dashboard',

  beginning: 'Beginning',
  beginningUsers: 'Beginning Users',
  beginningCashs: 'Beginning Cashs',
  beginningBanks: 'Beginning Banks',
  beginningWarehouse: 'Beginning Warehouse',

  categories: 'Categories',
  brands: 'Brands',
  attributes: 'Attributes',

  purchasesInvoice: 'Purchases Invoice',
  purchaseReternsInvoice: 'Purchase Reterns Invoice',

  warehousing: 'Warehousing',
  inventoryValuation: 'Inventory Valuation',

  banks: 'Banks',
  cash: 'Cash',
  transaction: 'Transaction',
  transference: 'Transference',

  accounting: 'Accounting',
  accountingTable: 'Accounting Table',
  income: 'Income',
  cost: 'Cost',


  logout: 'Logout',

  // navbar actions
  navbar: {
    back: 'back',
    save: 'save'
  },

  // login page
  login: {
    title: 'Login',

    getPhoneNumberStepMessage1: 'Please Enter Your Phone Number. A ',
    getPhoneNumberStepMessage2: '(5-digit code)',
    getPhoneNumberStepMessage3: ' will be sent to confirm your number.',
    phoneNumber: 'phone number',
    phoneNumberIsNotValid: 'phone number is not valid',
    phoneNumberIsNull: 'phone number is null',

    otpCodeSentStepMessage1: 'A 5-digit code was sent to ',
    otpCodeSentStepMessage2: '. Please enter it',
    otpCodeIsNotValid: 'otp code is not valid',
    otpCodeIsNull: 'otp code is null',
    retrieveTheCode: 'Retrieve the code',

    getSuperAdminTokenStepMessage: 'Please Enter Your Token.',
    token: 'system admin token',

    buttons: {
      getOTPCode: 'get OTP code',
      login: 'login'
    },

    notification: {
      login: {
        title: 'Message',
        message: 'You have successfully logged in'
      },

      logout: {
        title: 'ٰMessage',
        message: 'You have successfully logged out'
      },

      forceLogout: {
        title: 'Message',
        message: 'Please log in again due to long-term use of the system'
      }
    }
  },

  // profile page
  profile: {
    title: 'Profile',

    actions: {
      save: 'save',
      edit: 'edit'
    },

    edit: {
      title: 'edit profile'
    },

    buttons: {
      save: 'save changes',
      reset: 'reset',
      removeAvatar: 'Remove',
      uploadAvatar: 'Upload avatar'
    },

    General: {
      title: 'General',
      avatar: {
        rule: 'Allowed JPG, JPEG or PNG. Max size of 800kB'
      },
      user: {
        name: 'name',
        lastName: 'last name',
        role: 'role',
        phoneNumber: 'phone number',
        address: 'address',
        maxDebt: 'max debt',
        balance: 'balance'
      }
    },

    MyFinancialReports: {
      title: 'My Financial Reports'
    }
  },

  smsPanel: 'SMS Panel',

  // opening inventories
  opening: {
    users: {
      title: 'اول دوره اشخاص'

    },

    cashBoxes: {
      title: 'اول دوره صندوق ها'

    },

    banks: {
      title: 'اول دوره بانک ها'

    },

    stores: {
      title: 'اول دوره انبار ها'

    }
  },

  // companies
  companies: {
    title: 'Companies List',

    table: {
      header: {
        row: 'row',
        code: 'code',
        name: 'company name',
        equity: 'equity',
        totalDebt: 'total debt',
        totalAsset: 'total asset'
      }
    },

    company: {
      title: 'Company'
    },

    insert: {
      title: 'Insert Company'
    },

    edit: {
      title: 'Edit Company'
    }
  },

  // backup page
  backup: {
    title: 'Backup'
  },

  // plugins page
  plugins: {
    title: 'Plugins',

    wooreceiver: {
      title: 'Woocommerce Connection Maker'
    }
  },

  // setting page
  setting: {
    title: 'Settings',

    buttons: {
      save: 'save changes',
      reset: 'reset',
      removeLogo: 'Remove',
      uploadLogo: 'Upload logo'
    },

    clubInfo: {
      title: 'Company',

      labels: {
        generalInfo: 'General info',
        telInfo: 'Tell info',
        financialInfo: 'Financial info',
        uploadLogo: 'Upload logo',
        preview: 'Preview:',
        name: 'Company name',
        managerName: 'Manager full name',
        country: 'Country',
        province: 'State',
        city: 'City',
        address: 'Address',
        phoneNumber: 'Phone number',
        whatsAppSupport: 'WhatsApp support phone number',
        managerPhoneNumber: 'Manager phone number'
      },
    },

    message: {
      title: 'SMS panel',

      labels: {
        messengerKey: 'Messanger key',
        messengerUsername: 'Username',
        messengerPassword: 'Password',
        messengerSenderNumber: 'Sender Number',
        templates: 'Templates',
        login: 'Login templates',
        signup: 'Sign up templates',
        confirmInfo: 'Confirm templates',
        enableCancelReserve: 'Enable cancel reserve message',
        enableDailyReport: 'Enable daily report message',
        enableNewReceiveByClub: 'Enable new receive by club  message',
        enableNewReceiveByCustomer: 'Enable new receive by customer message',
        enableNewReserveTime: 'Enable new reserve time message',
        newReserveTimeMessagePattern: 'Reserve time message pattern',
        enableNewReserveCoach: 'Enable new reserve coach message',
        enableWelcome: 'Enable welcome message'
      },

      invalidTexts: {
        onlyEnglish: 'فقط حروف انگلیسی مجاز است!',
      }
    },

    access: {
      title: 'Access Groups',

      insert: {
        title: 'Insert New Access Group'
      },

      edit: {
        title: 'Edit Access Group'
      },

      table: {

        header: {
          row: 'row',
          name: 'access name'
        }
      },

      actions: {
        insert: 'insert',
        save: 'save'
      }
    },

    financial: {
      title: 'Financial',

      wage: 'Wage',
      NegativeValue: 'Bank and fund can be negative',

      labels: {
        cardToCardWage: 'Card to card wage',
        ibanWage: 'IBAN wage'
      },

      notifications: {
        negativeBalanceError: 'Due to the negative balance of a number of banks or funds, the desired settings has error',
        wrongValues: 'The entered information is not valid',

        edit: {
          success: 'Financial information has been updated successfully',
          error: 'Update financial information has error'
        }
      }
    },

    General: {
      title: 'General',
      logo: {
        rule: 'Allowed JPG, JPEG or PNG. Max size of 800kB'
      },
      club: {
        code: 'company code',
        name: 'company name',
        managerName: 'manger name',
        address: 'address',
        phoneNumber: 'phone number',
        managerPhoneNumber: 'manger phone number'
      }
    },

    Treasury: {
      title: 'Treasury',
      rule: 'Rules',
      NegativeValue: 'Banks and funds have the ability to be negative'
    },

    Locale: {
      title: 'Locale',
      language: 'language',
      basicSetting: 'Basic Setting'
    },

    Store: {
      title: 'Store'
    },

    Buy: {
      title: 'Buy'
    },

    Sale: {
      title: 'Sale'
    }
  },

  // Products Pages
  products: {
    title: 'Product and service',

    productsList: {
      title: 'products list',
      table: {
        header: {
          row: 'row',
          name: 'product name',
          code: 'product code',
          barcode: 'barcode',
          price: 'price',
          image: 'product image'
        },
        filters: {
          name: {
            search: 'be included',
            equals: 'be equal',
            notEqual: 'is not equal'
          },
          code: {
            equals: 'be equal',
            notEqual: 'is not equal',
            lessThanEquals: 'less than equals',
            greaterThanEquals: 'greater than equals'
          }
        }
      },
      actions: {
        insert: 'insert'
      }
    },

    productsPricing: {
      title: 'products pricing'
    },

    product: {
      title: 'product',
      productCategory: 'category: ',
      productBrand: 'brand: ',
      attributes: {
        title: 'attributes'
      },
      actions: {
        edit: 'edit'
      }
    },

    insertProduct: {
      title: 'insert product',

      accordionList: {
        brand: {
          title: 'select brand',

          insert: {
            title: 'insert new brand',
            brand: 'brand name'
          },

          edit: {
            title: 'edit brand',
            category: 'brand name'
          },

          delete: {
            title: 'delete brand',
            message: 'by deleting this brand, all related information will be deleted.'
          }
        },

        category: {
          title: 'select category',

          insert: {
            title: 'insert new category',
            category: 'category name'
          },

          edit: {
            title: 'edit category',
            category: 'category name'
          },

          delete: {
            title: 'delete category',
            message: 'by deleting this category, all related information will be deleted.'
          }
        },


        attributes: 'attributes',
        insertAttribute: {
          title: 'insert new attribute',
          attribute: 'attribute name',
          values: 'values'
        },
        editAttribute: {
          title: 'edit attribute',
          attribute: 'attribute name',
          values: 'values'
        },

        buttons: {
          accept: 'accept',
          cancel: 'cancel'
        },

        alert: {
          title: 'Message',
          brandCreated: 'the brand was successfully created',
          brandUpdated: 'the brand was successfully edited',
          brandDeleted: 'the brand was successfully deleted',
          categoryCreated: 'the category was successfully created',
          categoryUpdated: 'the category was successfully edited',
          categoryDeleted: 'the category was successfully deleted',
          errorTitle: 'Error',
          error: 'The process stopped with error'
        }
      },

      actions: {
        save: 'save',
        delete: 'delete'
      }
    },

    editProduct: {
      title: 'edit product'

    },

    categories: {
      title: 'categories'
    },

    brands: {
      title:  'brands'
    },

    attributes: {
      title: 'attributes'
    }
  },

  // Warehouse Pages
  warehouse: {
    title: 'Warehouse',

    stores: {
      title: 'Stores'
    },

    inventory: {
      title: 'Stores Inventory'
    },

    inventoryValuation: {
      title: 'Stores Inventory Valuation'
    },

    receipts: {
      title: 'Warehouse Receipts'
    },

    received: {
      title: 'Received Warehouse'
    },

    sended: {
      title: 'Sended Warehouse'
    }
  },

  // Accountancy Pages
  accountancy: {
    title: 'Accountancy',

    types: {
      title: 'Accounts'
    },

    openingBalance: {
      title: 'Opening Balance'
    },

    documents: {
      title: 'Documents',

      document: {
        title: 'Document'
      },

      insert: {
        title: 'Insert Document'
      }
    }
  },

  // Users Page
  users: {
    title: 'Users',

    customers: {
      title: 'Customers',

      insert: {
        title: 'Insert Customer',
        gender: 'gender',
        man: 'man',
        woman: 'woman',
        name: 'name',
        lastName: 'last name',
        phoneNumber: 'phone number',
        group: 'group',
        balance: 'balance',
        save: 'save',
        delete: 'delete'
      },

      profile: {
        title: ' Customer Profile',
        creditAmount: 'credit amount',
        debtAmount: 'debt amount',
        userContactTitle: 'User Contact Information',
        userContactContent: 'This box displays the user contact information.',
        phoneNumber: 'phone number',
        address: 'address',
        userResponsibilityTitle: 'User Responsibility Information',
        userResponsibilityContent: 'This box displays the user responsibility[s] information.',
        ResponsibilityGroup: 'responsibility group',
        salesInvoice: 'Sales Invoice',
        returnedSalesInvoice: 'returned Sales Invoice',
        reports: 'reports',
        paymentToCustomer: 'Payment to the customer',
        receiveFromCustomer: 'Receive from customer'
      },

      edit: {
        title: 'Edit Customer'

      },

      payment: {
        title: 'Pay to Customer'

      },

      receive: {
        title: 'Receive from Customer'

      },

      transactions: {
        title: 'Customer Transactions',

        table: {

          header: {
            docNumber: 'doc Number',
            date: 'date',
            documentation: 'documentation',
            price: 'price',
            balance: 'balance'
          }
        }
      },

      table: {

        header: {
          row: 'Row',
          image: 'Avatar',
          name: 'Name',
          balance: 'Balance'
        },

        filters: {
          title: 'Filtering customers',
          remove: 'remove filters',

          group: {
            title: 'Customers Group'
          },

          gender: {
            title: 'Gender',
            man: 'man',
            woman: 'woman'
          }
        }
      },

      actions: {
        insert: 'insert',
        edit: 'edit',
        save: 'save',
        delete: 'delete'
      }
    },

    suppliers: {
      title: 'Suppliers',

      insert: {
        title: 'Insert Supplier',
        gender: 'gender',
        man: 'man',
        woman: 'woman',
        name: 'name',
        lastName: 'last name',
        phoneNumber: 'phone number',
        group: 'group',
        balance: 'balance',
        save: 'save',
        delete: 'delete'
      },

      profile: {
        title: ' Supplier Profile',
        creditAmount: 'credit amount',
        debtAmount: 'debt amount',
        userContactTitle: 'User Contact Information',
        userContactContent: 'This box displays the user contact information.',
        phoneNumber: 'phone number',
        address: 'address',
        userResponsibilityTitle: 'User Responsibility Information',
        userResponsibilityContent: 'This box displays the user responsibility[s] information.',
        ResponsibilityGroup: 'responsibility group',
        salesInvoice: 'Sales Invoice',
        returnedSalesInvoice: 'returned Sales Invoice',
        reports: 'reports',
        paymentToSupplier: 'Payment to the supplier',
        receiveFromSupplier: 'Receive from supplier'
      },

      edit: {
        title: 'Edit Supplier'

      },

      payment: {
        title: 'Pay to Supplier'

      },

      receive: {
        title: 'Receive from Supplier'

      },

      transactions: {
        title: 'Supplier Transactions'

      },

      actions: {
        insert: 'insert',
        edit: 'edit',
        save: 'save',
        delete: 'delete'
      }
    },

    employees: {
      title: 'Employees',

      insert: {
        title: 'Insert Employee',
        gender: 'gender',
        man: 'man',
        woman: 'woman',
        name: 'name',
        lastName: 'last name',
        phoneNumber: 'phone number',
        group: 'group',
        balance: 'balance',
        save: 'save',
        delete: 'delete'
      },

      profile: {
        title: ' Employee Profile',
        creditAmount: 'credit amount',
        debtAmount: 'debt amount',
        userContactTitle: 'User Contact Information',
        userContactContent: 'This box displays the user contact information.',
        phoneNumber: 'phone number',
        address: 'address',
        userResponsibilityTitle: 'User Responsibility Information',
        userResponsibilityContent: 'This box displays the user responsibility[s] information.',
        ResponsibilityGroup: 'responsibility group',
        salesInvoice: 'Sales Invoice',
        returnedSalesInvoice: 'returned Sales Invoice',
        reports: 'reports',
        paymentToEmployee: 'Payment to the employee',
        receiveFromEmployee: 'Receive from employee',
        salaryReceipt: 'employee salary receipt'
      },

      edit: {
        title: 'Edit Employee'

      },

      payment: {
        title: 'Pay to Employee'

      },

      receive: {
        title: 'Receive from Employee'

      },

      transactions: {
        title: 'Employee Transactions'

      },

      salaryReceipt: {
        title: 'Employee Salary Receipt',
        price: 'amount(Toman)',
        description: 'description'
      },

      actions: {
        insert: 'insert',
        edit: 'edit',
        save: 'save',
        delete: 'delete'
      }
    },

    shareHolders: {
      title: 'Share Holders',

      insert: {
        title: 'Insert Share Holder',
        gender: 'gender',
        man: 'man',
        woman: 'woman',
        name: 'name',
        lastName: 'last name',
        phoneNumber: 'phone number',
        group: 'group',
        balance: 'balance',
        save: 'save',
        delete: 'delete'
      },

      profile: {
        title: ' Share Holder Profile',
        creditAmount: 'credit amount',
        debtAmount: 'debt amount',
        userContactTitle: 'User Contact Information',
        userContactContent: 'This box displays the user contact information.',
        phoneNumber: 'phone number',
        address: 'address',
        userResponsibilityTitle: 'User Responsibility Information',
        userResponsibilityContent: 'This box displays the user responsibility[s] information.',
        ResponsibilityGroup: 'responsibility group',
        salesInvoice: 'Sales Invoice',
        returnedSalesInvoice: 'returned Sales Invoice',
        reports: 'reports',
        paymentToShareHolder: 'Payment to the share holder',
        receiveFromShareHolder: 'Receive from share holder'
      },

      edit: {
        title: 'Edit Share Holder'

      },

      payment: {
        title: 'Pay to Share Holder'

      },

      receive: {
        title: 'Receive from Share Holder'

      },

      transactions: {
        title: 'Share Holder Transactions'

      }
    },

    table: {

      header: {
        row: 'row',
        select: 'select',
        registryDate: 'registry date',
        phoneNumber: 'phone number',
        company: 'company',
        gender: 'gender',
        name: 'name',
        score: 'score',
        group: 'group',
        accessGroup: 'access group',
        maxDebt: 'max debt',
        balance: 'balance',
        address: 'address',
        access: 'access',
        status: 'status'
      }
    },

    transactions: {

      table: {

        header: {
          docNumber: 'doc Number',
          date: 'date',
          documentation: 'documentation',
          price: 'price',
          balance: 'balance'
        }
      }
    },

    actions: {
      insert: 'insert',
      edit: 'edit',
      save: 'save',
      delete: 'delete'
    }
  },

  // documents page
  documents: {
    title: 'Documents',

    document: {

      table: {

        header: {
          row: 'row',
          docNumber: 'document number',
          date: 'date',
          reference: 'reference',
          details: 'details',
          price: 'price',
          type: 'type'
        }
      }
    }
  },

  //sales
  sales: {
    title: 'Sales',

    proformaInvoices: {
      title: 'Proforma Invoices'

    },

    invoices: {
      title: 'Sale Invoices',

      list: {
        title: 'Sale Invoices',

        table: {

          header: {
            number: 'invoice number',
            creator: 'creator',
            user: 'buyer',
            date: 'create date',
            totalPrice: 'total price',
            status: 'status'
          }
        }
      },

      insert: {
        title: 'Insert Sale Invoice',

        table: {

          header: {
            row: 'row',
            code: 'code',
            product: 'product',
            dynamic: 'dynamic',
            number: 'number',
            unitAmount: 'unit amount',
            amount: 'amount',
            store: 'store',
            action: '#'
          }
        }
      },

      invoice: {
        title: 'Sale Invoice'
      },

      edit: {
        title: 'Edit Sale Invoice'
      }
    },

    returnInvoices: {
      title: 'Return Sale Invoices',

      list: {
        title: 'Return Sale Invoices',

        table: {

          header: {
            number: 'invoice number',
            creator: 'creator',
            user: 'buyer',
            date: 'create date',
            totalPrice: 'total price',
            status: 'status'
          }
        }
      },

      insert: {
        title: 'Insert Return Sale Invoice',

        table: {

          header: {
            row: 'row',
            code: 'code',
            product: 'product',
            dynamic: 'dynamic',
            number: 'number',
            unitAmount: 'unit amount',
            amount: 'amount',
            store: 'store',
            action: '#'
          }
        }
      },

      invoice: {
        title: 'Return Sale Invoice'
      },

      edit: {
        title: 'Edit Return Sale Invoice'
      }
    },

    groups: {
      title: 'Sale Groups'
    },

    reternsInvoice: {
      title: 'Sale Reterns Invoice'
    },

    pricing: {
      title: 'Pricing',

      table: {

        header: {
          row: 'row',
          code: 'code',
          name: 'name',
          dynamic: 'dynamic',
          amount: 'amount',
          date: 'date',
          action: 'action'
        },

        content: {

          button: {
            set: 'set'
          }
        }
      }
    },

    discount: {
      title: 'Sales Discount'

    },

    actions: {
      insert: 'insert',
      edit: 'edit',
      filters: 'filters'
    }
  },

  //purchases
  purchases: {
    title: 'Purchases',

    invoices: {
      title: 'Purchase Invoices',

      list: {
        title: 'Purchase Invoices',

        table: {

          header: {
            number: 'invoice number',
            creator: 'creator',
            user: 'buyer',
            date: 'create date',
            totalPrice: 'total price',
            status: 'status'
          }
        }
      },

      insert: {
        title: 'Insert Purchase Invoice',

        table: {

          header: {
            row: 'row',
            code: 'code',
            product: 'product',
            dynamic: 'dynamic',
            number: 'number',
            unitAmount: 'unit amount',
            amount: 'amount',
            store: 'store',
            action: '#'
          }
        }
      },

      invoice: {
        title: 'Purchase Invoice'
      },

      edit: {
        title: 'Edit Purchase Invoice'
      }
    },

    returnInvoices: {
      title: 'Return Purchase Invoices',

      list: {
        title: 'Return Purchase Invoices',

        table: {

          header: {
            number: 'invoice number',
            creator: 'creator',
            user: 'buyer',
            date: 'create date',
            totalPrice: 'total price',
            status: 'status'
          }
        }
      },

      insert: {
        title: 'Insert Return Purchase Invoice',

        table: {

          header: {
            row: 'row',
            code: 'code',
            product: 'product',
            dynamic: 'dynamic',
            number: 'number',
            unitAmount: 'unit amount',
            amount: 'amount',
            store: 'store',
            action: '#'
          }
        }
      },

      invoice: {
        title: 'Return Purchase Invoice'
      },

      edit: {
        title: 'Edit Return Purchase Invoice'
      }
    },

    reternsInvoice: {
      title: 'Purchase Reterns Invoice'
    },

    actions: {
      insert: 'insert',
      edit: 'edit',
      filters: 'filters'
    }
  },

  //treasury
  treasury: {
    title: 'خزانه داری',

    banks: {
      title: 'Banks',

      insert: {
        title: 'Insert Bank'
      },

      list: {
        title: 'Banks'
      },

      edit: {
        title: 'Edit Bank'
      },

      bank: {
        title: 'Bank'
      },

      table: {
        header: {
          row: 'row',
          name: 'name',
          cardNumber: 'card number',
          accountNumber: 'account number',
          balance: 'balance'
        }
      }
    },

    paymentGateways: {
      title: 'Payment Gateways',

      insert: {
        title: 'Insert Payment Gateway'
      },

      list: {
        title: 'Payment Gateways'
      },

      edit: {
        title: 'Edit Payment Gateway'
      },

      paymentGateway: {
        title: 'Payment Gateways'
      }
    },

    cashBoxes: {
      title: 'Cash Boxes',

      insert: {
        title: 'insert Cash Box'
      },

      list: {
        title: ' Cash Boxes'
      },

      edit: {
        title: 'Edit  Cash Box'
      },

      cashBox: {
        title: ' Cash Box'
      },

      table: {
        header: {
          row: 'row',
          name: 'name',
          balance: 'balance'
        }
      }
    },

    transactions: {
      title: 'ّFinancial Transactions',

      list: {
        title: 'Financial Transactions'
      },

      insert: {
        title: 'Insert Transaction'
      },

      transaction: {
        title: 'Transaction'
      }
    },

    actions: {
      insert: 'insert',
      edit: 'edit',
      save: 'save',
      delete: 'delete'
    }
  },

  //reports
  reports: {
    title: 'Reports',

    logs: {
      title: 'logs'
    },

    usersTotalSales: {
      title: 'Total Sales To Users'
    }
  },

  //events
  events: {
    title: 'Events'
  },

  // draggable dynamic table
  draggableDynamicTable: {

    actions: {
      print: 'print',
      download: ' download',
      setting: 'table setting'
    }
  },

  // Button
  create: 'create'

}

<template>
  <div class="treasury-select-users-list">
    <draggable-dynamic-table ref="selectUsersList"
                             :data="data"
                             :columns="columnsLabel"
                             :options="options"
                             :in-modal="true"
                             @column:select="selectedRows = $event, checkStatus($event)"
                             @filter:set="setFilters($event)"
                             @filter:remove="setFilters($event)"
                             @sort:set="setSort($event)"
                             @load:more="getUsers()"/>


    <vs-button id="selectUsers" class="useral-action-button" @click="$emit('selected', selectedRows)"/>
  </div>
</template>

<script>
import {getUsers} from '@/http/requests/users/users'

export default {
  name: 'selectUsers',
  data () {
    return {
      checkStatusTimer: 0,
      selectedRows: [],
      insertPromptStatus: false,
      loadingTimer: 0,
      selectedCashBoxes: [],
      options: {
        rowKeyField: 'id'
      },
      columnsLabel: [
        {
          field: 'balance',
          i18n: 'users.table.header.balance',
          width: 'calc(100% / 10)',
          minWidth: 225,
          align: 'center',
          sortable: true,
          filter: true,
          filterTypes: [
            {
              icon: 'search',
              name: 'شامل شود',
              i18n: 'draggableTable.filter.types.search',
              id: 1
            }
          ]
          // locked: true
        },
        {
          field: 'phoneNumber',
          i18n: 'users.table.header.phoneNumber',
          width: 'calc((100% / 10))',
          minWidth: 200,
          align: 'center',
          sortable: true,
          // locked: true,
          filter: true,
          filterTypes: [
            {
              icon: 'search',
              name: 'شامل شود',
              i18n: 'draggableTable.filter.types.search',
              id: 1
            }
          ]
        },
        {
          field: 'name',
          i18n: 'users.table.header.name',
          width: 'calc((100% / 10) * 2)',
          minWidth: 250,
          sortable: true,
          // locked: true,
          filter: true,
          filterTypes: [
            {
              icon: 'search',
              name: 'شامل شود',
              i18n: 'draggableTable.filter.types.search',
              id: 1
            }
          ]
        },
        {
          field: 'gender',
          i18n: 'users.table.header.gender',
          width: 'calc(100% / 10)',
          minWidth: 100,
          align: 'center',
          sortable: true,
          filter: true,
          filterType: 'select',
          filterTypes: [
            {
              label: this.$t('users.genderTypes.all'),
              value: 0
            },
            {
              label: this.$t('users.genderTypes.man'),
              value: 1
            },
            {
              label: this.$t('users.genderTypes.woman'),
              value: 2
            }
          ]
        },
        {
          field: 'row',
          i18n: 'users.table.header.row',
          width: '50px',
          minWidth: 50,
          align: 'center',
          // locked: true,
          // sortable: true,
          footer: {
            /*type: 'auto-counter'*/
          }
        },
        {
          field: 'select',
          i18n: 'users.table.header.select',
          width: '50px',
          minWidth: 50,
          align: 'center',
          selectable: true,
          multiSelect: this.multiSelect,
          locked: true
        }
      ],
      data: [],
      page: 1,
      filters: [],
      sorts: ['order[0]=created_at,desc'],
      endedList: false
    }
  },
  props: {
    multiSelect: {
      type: Boolean,
      default: () => { return true }
    },
    openingBalance: {
      type: String,
      default: ''
    },
    defaultFilter: {
      type: Array,
      default: () => { return [] }
    },
    customer: {
      type: Boolean,
      default: () => { return false }
    },
    supplier: {
      type: Boolean,
      default: () => { return false }
    }
  },
  created () {

    if (this.openingBalance) {
      this.filters.push(`openingInventory=${this.openingBalance}`)
    }

    if (this.customer) {
      this.filters.push('customer=true')
    }

    if (this.supplier) {
      this.filters.push('supplier=true')
    }

    this.getUsers()
  },
  methods: {
    checkStatus (selected) {
      if (!this.multiSelect) {
        clearTimeout(this.checkStatusTimer)
        this.checkStatusTimer = setTimeout(() => {
          selected.forEach((select) => {
            if (select.show) this.$emit('selected', this.selectedRows)
          })
        }, 500)
      }
    },
    addComma (val) {
      let sign = ''

      if (val < 0) sign = '-'

      val = val.toString()

      if (val[0] === '0') val = val.substr(1, val.length - 1)

      return val.toString().replace(/\D/g, '')
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',') + sign
    },
    handleClick (id) {
      document.getElementById(id).click()
    },
    getUsers () {
      if (!this.requestSent) {
        this.requestSent = true

        clearTimeout(this.loadingTimer)
        this.loadingTimer = setTimeout(() => {
          if (!this.endedList) {
            if (this.$refs.selectUsersList && this.data.length === 0) {
              this.$refs.selectUsersList.loadMoreStatus = 'FirstLoad'
            } else if (this.$refs.selectUsersList && this.data.length > 0) {
              this.$refs.selectUsersList.loadMoreStatus = 'Loading'
            }

            getUsers(this.page, this.filters, this.sorts).then((res) => {
              this.endedList = res.data.data.length === 0
              res.data.data.forEach((user) => {
                const shops = []
                if (user.received && user.received.synced) {
                  shops.push(user.received.shop.name)
                }

                this.data.push({
                  row: this.data.length + 1,
                  id: user.id,
                  registryDate: user.created_at,
                  gender: user.gender === 1 ? this.$t('users.genderTypes.man') : this.$t('users.genderTypes.woman'),
                  phoneNumber: user.phone_number ? user.phone_number : '',
                  firstName: user.name || '',
                  lastName: user.family || '',
                  name: `${  user.name  } ${  user.family}`,
                  avatar: user.avatar || '',
                  balance: {
                    type: 'price',
                    value: Math.abs(user.balance) || 0,
                    classes: user.balance === 0 ? 'text-primary' : user.balance < 0 ? 'text-success' : 'text-danger'
                  },
                  nationalCode: user.national_code ? user.national_code : '',
                })
              })

              this.page = res.data.pagination.current_page + 1
              if (res.data.pagination.current_page === 1) {
                const row_index = this.columnsLabel.map((e) => {
                  return e.field
                }).indexOf('row')
                this.columnsLabel[row_index].footer.value = res.data.pagination.total
              }

              if (this.$refs.selectUsersList) this.$refs.selectUsersList.loadMoreStatus = ''

              this.requestSent = false
            })
                    .catch(() => {
                      if (this.$refs.selectUsersList) this.$refs.selectUsersList.loadMoreStatus = 'Danger'

                      this.requestSent = false
                    })
          } else {

            this.requestSent = false
          }
        }, 400)
      }
    },
    setFilters (filters) {
      const filters_list = []
      Object.keys(filters).forEach((key) => {
        switch (key) {

        case 'accessGroup':
          if (filters[key].search !== '') filters_list.push(`role=${filters[key].search}`)
          break

        case 'maxDebt':
          if (filters[key].search !== '') filters_list.push(`max_debt=${filters[key].search},${filters[key].type.id}`)
          break

        case 'phone_number':
          if (filters[key].search !== '') filters_list.push(`phone_number=${filters[key].search}`)
          break

        case 'company':
          if (filters[key].search !== '') filters_list.push(`company=${filters[key].search}`)
          break

        case 'name':
          if (filters[key].search !== '') filters_list.push(`full_name=${filters[key].search}`)
          break

        case 'balance':
          if (filters[key].search !== '') filters_list.push(`balance=${filters[key].search}`)
          break
        }
      })

      if (this.defaultFilter.length) {
        this.defaultFilter.forEach(item => {
          filters_list.push(item)
        })
      }

      if (this.openingBalance) {
        this.filters.push(`openingInventory=${this.openingBalance}`)
      }

      if (this.customer) {
        filters_list.push('customer=true')
      }

      if (this.supplier) {
        filters_list.push('supplier=true')
      }

      this.data = []
      this.page = 1
      this.endedList = false
      this.filters = filters_list
      this.getUsers()
    },
    setSort (columns) {
      const sorts_list = []
      Object.keys(columns).forEach((key) => {
        switch (key) {

        case 'row':
          sorts_list.push(`order[0]=id,${columns[key] ? 'desc' : 'asc'}`)
          break

          /*case "accessGroup":
            sorts_list.push("order[0]=role," + (columns[key] ? 'desc' : 'asc'))
            break;*/

        case 'maxDebt':
          sorts_list.push(`order[0]=max_debt,${columns[key] ? 'desc' : 'asc'}`)
          break

        case 'balance':
          sorts_list.push(`order[0]=balance,${columns[key] ? 'desc' : 'asc'}`)
          break

        case 'phone_number':
          sorts_list.push(`order[0]=phone_number,${columns[key] ? 'desc' : 'asc'}`)
          break

        case 'company':
          sorts_list.push(`order[0]=company,${columns[key] ? 'desc' : 'asc'}`)
          break

        case 'name':
          sorts_list.push(`order[0]=name,${columns[key] ? 'desc' : 'asc'}`)
          break

        case 'registryDate':
          sorts_list.push(`order[0]=created_at,${columns[key] ? 'desc' : 'asc'}`)
          break

        case 'group':
          sorts_list.push(`order[0]=group,${columns[key] ? 'desc' : 'asc'}`)
          break

        case 'gender':
          sorts_list.push(`order[0]=gender,${columns[key] ? 'desc' : 'asc'}`)
          break
        }
      })

      if (sorts_list.length === 0) sorts_list.push('order[0]=created_at,desc')

      this.data = []
      this.page = 1
      this.endedList = false
      this.sorts = sorts_list
      this.getUsers()
    }
  }
}
</script>

<style scoped>
.treasury-select-users-list {
  height: 100%;
}
</style>

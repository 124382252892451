<template>
  <div class="draggable-dynamic-table w-full">
    <draggable-dynamic-table-setting :status="tableSettingModalStatus"
                                     :settings="settings"
                                     :active-settings="activeSettings"
                                     @input="setSetting($event)"
                                     @setting:closed="tableSettingModalStatus = false"/>

    <vs-row class="hide-print">
      <vs-spacer/>

      <vs-col class="w-auto">
        <export-excel
          class="hidden"
          id="downloadTable"
          :data="export_data.json_data"
          :fields="export_data.json_fields"
          :meta="export_data.json_meta"
          :worksheet="options.name || options.id"
          :name="`${options.name || options.id}.xls`">
        </export-excel>

        <!--<vs-button class="useral-action-button useral-colors color-black&#45;&#45;300 table-toolbar-button"
                   icon="icon-download"
                   icon-pack="feather"
                   @click="tableSettingModalStatus = !tableSettingModalStatus"/>-->

        <vs-button class="useral-action-button useral-colors color-black--300 table-toolbar-button"
                   id="printTable"
                   icon="icon-printer"
                   icon-pack="feather"
                   @click="printPage()"/>

        <vs-button class="useral-action-button useral-colors color-black--300 table-toolbar-button"
                   id="settingTable"
                   icon="icon-settings"
                   icon-pack="feather"
                   @click="tableSettingModalStatus = true"/>
      </vs-col>
    </vs-row>

    <vs-row class="table-wrapper">

      <div class="root-wrapper" :class="{'full-border': inModal || inCard, 'has-filters': filters.length > 0 && showFilterBtn}">
        <div class="root">
          <div class="root-header">
            <draggable v-model="headers" @end="updateUserBrowserSettings" tag="div" class="root-row root-header-row root-header-title">
              <div v-if="!column.hasOwnProperty('show') || column.show || column.locked"
                   class="root-header-cell text-center"
                   :class="{'cursor-pointer': column.sortable || column.filter}"
                   v-for="(column, column_index) in headers"
                   :key="`header_${column_index}`"
                   @click="column.sortable ? sortColumn(column.field) : ''"
                   :style="columnsStyles[column.field] || getRowStyles(column)">

                <div class="column-label">
                  {{ $t(column.i18n) || column.headerName || column.field }}
                </div>

                <span v-show="column.sortable && !sortStatus.hasOwnProperty(column.field)"
                      :class="[`ordering-icon ordering-${column.field}-icon`]">
                      <template>
                        <font-awesome-icon icon="sort-amount-down-alt"></font-awesome-icon>
                      </template>
                </span>

                <span v-show="column.sortable && sortStatus.hasOwnProperty(column.field) && sortStatus[column.field]"
                      :class="`ordering-icon ordering-${column.field}-icon`">
                      <template>
                        <font-awesome-icon icon="sort-amount-down"></font-awesome-icon>
                      </template>
                </span>

                <span v-show="column.sortable && sortStatus.hasOwnProperty(column.field) && !sortStatus[column.field]"
                      :class="`ordering-icon ordering-${column.field}-icon`">
                      <template>
                        <font-awesome-icon icon="sort-amount-down-alt"></font-awesome-icon>
                      </template>
                </span>
              </div>
            </draggable>

            <div v-if="checkFilters" class="root-row root-header-row hide-print">
              <div v-if="!column.hasOwnProperty('show') || column.show || column.locked"
                   v-for="(column, column_index) in headers"
                   :key="`header_${column_index}`"
                   class="root-header-filter-cell"
                   :style="columnsStyles[column.field] || getRowStyles(column)">

                <filter-box v-if="column.filter"
                            :field="column.field"
                            :filters="column.filterTypes || []"
                            :range="column.filterRange ? column.filterRange : false"
                            :type="column.filterType ? column.filterType : 'search'"
                            :default-value="column.filterDefault ? column.filterDefault : ''"
                            @removeFilters="$emit('filter:remove', filterStatus)"
                            @input="setFilters"
                            v-model="filterStatus[column.field]"/>

                <span v-else>#</span>
              </div>
            </div>
          </div>

          <div @scroll="handleScroll"
               class="root-body rtl"
               :class="{'fixed-screen': fixScreen, 'without-filters': !checkFilters, 'without-footer': !checkFooter}">
            <div v-for="(row, row_index) in dataList"
                 :key="`row_${row_index}`"
                 class="root-row root-body-row"
                 :class="{'active-grid': activeGrid, 'last-root-row': checkLastRow(row_index)}"
                 :style="row.styles ? row.styles : ''">

              <div class="root-row-box"
                   @click="$emit('row:clicked', row), handleRowClicked(row_index)">
                <router-link :to="{name: row.route ? row.route.name : '', params: row.route ? row.route.params : '', query: row.route ? row.route.hasOwnProperty('query') ? row.route.query : $route.query || null : $route.query}" :target="row.route && row.route.target ? row.route.target : ''">

                  <div v-if="!column.hasOwnProperty('show') || column.show || column.locked"
                       v-for="(column, column_index) in headers"
                       :key="`header_${column_index}`"
                       class="root-body-cell"
                       :class="{'overflow-visible': column.dropDown}"
                       :style="columnsStyles[column.field] || getRowStyles(column)">

                    <input-box v-if="column.editable"
                               :align="column.align || 'center'"
                               :return-value="column.returnValue"
                               :ref="`input_${column.field}_${row[options.rowKeyField]}`"
                               :type="column.valueType ? column.valueType : ''"
                               :currency="row[`${column.field}_preContent`] ? row[`${column.field}_preContent`] : ''"
                               @enter:keyUp="column.valueEnterKeyUpEvent ? $emit(column.valueEnterKeyUpEvent, {index: row_index, data: $event}): ''"
                               @focus="handleSuggestListSearch(column, row, row_index, true), $emit('editableColumnClick', column, row, row_index)"
                               @input="handleSuggestListSearch(column, row, row_index),
                                 handleSetStatusFlag(row),
                                 column.footer ? getFooterValue(column) : '',
                                 column.valueEvent ? $emit(column.valueEvent, {index: row_index, data: $event}): ''"
                               v-model="row[column.field]"/>


                    <custom-select v-else-if="column.dropDown"
                                   :default="row[column.field].selected"
                                   :options="row[column.field].list || column.list"
                                   @suggest:selected="$emit('dropDown:selected', $event)"
                                   v-model="row[column.field].selected"/>

                    <custom-check-box ref="customDraggableDynamicTableCheckBox"
                                      v-else-if="column.selectable"
                                      v-model="selectedData"
                                      :row-selectable="options.hasOwnProperty('rowSelectable') ? options.rowSelectable : true"
                                      :multi="column.hasOwnProperty('multiSelect') ? column.multiSelect : true"
                                      :value="selectedData[row_index]"/>

                    <span v-else-if="column.autoIncrement"
                          class="ellipsis-overflow ltr">
                      {{ row_index + 1 }}
                  </span>

                    <actions-box v-else-if="column.actions"
                                 :class="column.classes ? column.classes : ''"
                                 :color="column.action.color"
                                 :text="column.action.text"
                                 :icon="column.action.icon"
                                 :icon-pack="column.action.iconPack"
                                 :type="column.action.type || 'reset_input'"
                                 :show="column.showAction"
                                 :look-field="column.lookField"
                                 :data="row"
                                 @clicked="handleActionButton(column, row)"/>

                    <lazy-image v-else-if="typeof row[column.field] === 'object' && row[column.field].type === 'img'"
                                :src="row[column.field].src"
                                :alt="row[column.field].src"
                                :class="row[column.field].class ? row[column.field].class : ''"/>

                    <span v-else-if="typeof row[column.field] === 'object'"
                          class="ellipsis-overflow ltr"
                          :class="[row[column.field].classes ? row[column.field].classes : '', row[column.field].type === 'price' && row[column.field].value !== 0 ? 'price' : '']"
                          :style="row[column.field].styles ? row[column.field].styles : ''">

                        {{ column.type === 'relation' ? getRelationValue(row, column) : '' }}

                        {{
                          row[column.field].type === 'price' ? addComma(row[column.field].value) || '0' : row[column.field].value
                        }}
                        <span class="currency" v-if="row[column.field].type === 'price' && row[column.field].value !== 0">{{ row[column.field].currency || $t('currency') }}</span>
                        <template  v-if="row[column.field].preContent">
                          <span class="currency" :class="typeof row[column.field].preContent === 'object' && row[column.field].preContent.hasOwnProperty('class') ? row[column.field].preContent.class : ''">
                            {{ row[column.field].preContent.hasOwnProperty('value') ? row[column.field].preContent.value : row[column.field].preContent }}
                          </span>
                        </template>
                    </span>

                    <span v-else class="ellipsis-overflow" :class="column.filterType === 'date' ? 'rtl' : 'ltr'">{{ row[column.field] }}</span>

                    <font-awesome-icon v-if="column.suggestPrompt"
                                       class="suggest-modal-button"
                                       icon="tasks"
                                       @click="$emit('suggestPrompt:opened', row_index), handleCloseSuggestBox(`suggestsList_${column.field}_${row_index}`)"/>

                    <font-awesome-icon v-if="column.suggestPrompt"
                                       class="new-suggest-modal-button"
                                       icon="plus"
                                       @click="$emit('newSuggestPrompt:opened', row_index), handleCloseSuggestBox(`suggestsList_${column.field}_${row_index}`)"/>
                  </div>

                </router-link>
              </div>

              <div class="root-row-children p-5" v-if="(row.childrenColumns || options.childrenColumns) && row.children">
                <draggable-dynamic-table :options="{rowKeyField: 'id'}"
                                         :columns="row.childrenColumns || options.childrenColumns"
                                         :data="row.children"
                                         @row:clicked="$emit('row:clicked', $event)"
                                         :fix-screen="false"
                                         :in-modal="true"/>

              </div>
            </div>
          </div>

          <div class="root-notifications">
            <!--            <transition name="fade">-->
            <div class="root-row root-notification-row" v-if="loadMoreStatus.length > 0">
              <div class="root-notification-cell">
                <div class="custom-loader" v-if="loadMoreStatus === 'FirstLoad'"></div>
                <p v-if="loadMoreStatus === 'Loading'">{{ $t('draggableDynamicTable.notifications.loading') }}</p>
                <p v-else-if="loadMoreStatus === 'Danger'" class="text-danger center">{{ $t('draggableDynamicTable.notifications.error') }}</p>
                <p v-else-if="loadMoreStatus === 'Empty'" class="text-warning center">{{ $t('draggableDynamicTable.notifications.empty') }}</p>
              </div>
            </div>
            <!--            </transition>-->
          </div>

          <div class="root-footer"
               v-if="checkFooter"
               :class="{'fixed-footer': fixScreen}">
            <div class="root-row root-footer-row">

              <div v-if="!column.hasOwnProperty('show') || column.show || column.locked"
                   v-for="(column, column_index) in headers"
                   :key="`header_${column_index}`"
                   class="root-footer-cell"
                   :class="[column.align ? `text-${column.align}` : 'text-center', column.color ? `text-${column.color}` : '']"
                   :style="columnsStyles[column.field] || getRowStyles(column)">
                {{
                  column.footer && column.footer.value ? column.footer.value : column.footer && column.footer.type ? footerValues[column.field] : ''
                }}
              </div>
            </div>
          </div>
        </div>

        <div class="side-bar" v-show="filters.length > 0">
          <div class="side-bar-buttons" v-show="showFilterBtn">
            <button class="side-bar-button"
                    id="toggleActiveFilter"
                    @click="sidebarStatus.filters = !sidebarStatus.filters , sidebarStatus.columns = false">
              <custom-icon width="15px" height="15px" color="dark-gray" icon="FILTER"/>
              <span>{{ $t('draggableDynamicTable.filters') }}</span>
            </button>
          </div>

          <div v-if="sidebarStatus.filters" class="side-bar-wrapper-overflow" @click="handleClickOutsideActiveFilter"></div>

          <div class="side-bar-wrapper">
            <div class="side-bar-filters" >
              <active-filters-box :filters="filters" v-model="filterStatus"/>

              <div v-for="(filter, filter_index) in filters"
                   :key="`filter_${filter_index}`"
                   class="side-bar-filter">

                <div class="side-bar-filter-label">
                  {{ $t(filter.i18n) || filter.name || filter.field }}
                </div>

                <div>
                  <filter-box :filters="filter.types || []"
                              :field="filter.field"
                              :type="filter.type ? filter.type : 'search'"
                              @removeFilters="$emit('filter:remove', filterStatus)"
                              @input="setFilters"
                              v-model="filterStatus[filter.field]"/>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </vs-row>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import {addComma} from '@/assets/js/functions'
import FilterBox from '@/components/draggableDynamicTable/filterBox'
import {mapActions, mapGetters} from 'vuex'
import CustomCheckBox from '@/components/customCheckBox/customCheckBox'
import DraggableDynamicTableSetting from '@/components/draggableDynamicTable/draggableDynamicTableSetting'
import InputBox from '@/components/draggableDynamicTable/InputBox'
import ActionsBox from '@/components/draggableDynamicTable/actionsBox'
import SuggestBox from '@/components/draggableDynamicTable/suggestBox'
import CustomSelect from '@/components/customSelect/customSelect'
import LazyImage from '@/components/lazyImage/lazyImage'
import SelectBanks from '@/views/admin/treasury/banks/select/selectBanks'
import CustomIcon from '../customIcon/customIcon'
import ActiveFiltersBox from './activeFiltersBox'

export default {
  name: 'draggableDynamicTable',
  components: {
    ActiveFiltersBox,
    CustomIcon,
    SelectBanks,
    LazyImage,
    CustomSelect,
    SuggestBox,
    ActionsBox,
    InputBox,
    DraggableDynamicTableSetting,
    CustomCheckBox,
    FilterBox,
    draggable
  },
  model: {
    prop: 'data',
    event: 'input'
  },
  props: {
    options: {},
    suggests: {},
    columns: {
      type: Array,
      default: () => {
        return []
      }
    },
    filters: {
      type: Array,
      default: () => {
        return []
      }
    },
    showFilterBtn: {
      type: Boolean,
      default: () => {
        return true
      }
    },
    data: {
      type: Array,
      default: () => {
        return []
      }
    },
    activeGrid: {
      type: Boolean,
      default: false
    },
    fixScreen: {
      type: Boolean,
      default: true
    },
    zeroTopRadius: {
      type: Boolean,
      default: true
    },
    inCard: {
      type: Boolean,
      default: false
    },
    inModal: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      columnsStyles: {},
      sidebarStatus: {
        filters: false,
        columns: false
      },
      firstVisit: true,
      loadMoreStatus: '',
      handleScrollTimer: 0,
      closeActiveFilterTimer: 0,
      oldScrollTop: 0,
      isTypingTimer: 0,
      minTableWidth: 0,
      updateTableSettingTimer: 0,
      tableSettingModalStatus: false,
      headerSelectable: false,
      headers: [],
      dataList: [],
      footerValues: {},
      selectedData: [],
      sortStatus: {},
      filterStatus: {},
      suggestPromptStatus: false,
      cancelEditStatus: {},
      settings: {
        headers: {
          field: 'headers',
          title: this.$t('draggableDynamicTable.setting.showColumns'),
          type: 'checkbox',
          options: []
        }
      },
      activeSettings: {},
      export_data: {
        json_fields: {},
        json_data: [],
        json_meta: [
          [
            {
              'key': 'charset',
              'value': 'utf-8'
            }
          ]
        ]
      },
      actions: [
        {
          toolbar: [
            {
              id: 'printTable',
              // i18n: 'draggableDynamicTable.actions.print',
              icon: 'icon-printer',
              iconPack: 'feather'
            },
            {
              id: 'downloadTable',
              // i18n: 'draggableDynamicTable.actions.download',
              icon: 'icon-download',
              iconPack: 'feather'
            }
          ],
          leftToolbar: [
            {
              id: 'settingTable',
              icon: 'icon-settings',
              iconPack: 'feather'
            }
          ]
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      tablesSetting: 'table/getTableSetting'
    }),
    verticalNavMenuWidth () {
      return this.$store.state.verticalNavMenuWidth
    },
    classObj () {
      if (this.verticalNavMenuWidth === 'default') return 'table-default'
      else if (this.verticalNavMenuWidth === 'reduced') return 'table-reduced'
      else if (this.verticalNavMenuWidth) return 'table-full'
    },
    checkFilters () {
      let counter = 0
      this.headers.forEach((header) => {
        if (header.filter) {
          counter++
        }
      })
      if (counter === 0) return false
      else return true
    },
    checkFooter () {
      let counter = 0
      this.headers.forEach((header) => {
        if (header.footer) {
          counter++
        }
      })
      if (counter === 0) return false
      else return true
    }
  },
  /*beforeCreate() {
    this.$store.dispatch('table/getUserBrowserSettings');
  },*/
  created () {
    setTimeout(() => {
      this.loadMoreStatus = true
    }, 3000)
    // this.$store.dispatch('updateNavbarActions', this.actions[0])

    this.setTableData()

    // save table setting when page reloaded and reset data after load
    const index_table_id = Object.keys(this.tablesSetting).indexOf(this.options.id)
    let tableSettings = []
    if (this.options.id && index_table_id > -1 && this.tablesSetting[this.options.id].length === this.columns.length) {
      this.headers = []
      tableSettings = []
      this.export_data.json_fields = {}

      this.tablesSetting[this.options.id].forEach((header) => {
        const field_id = this.columns.map((elm) => {
          return elm.field
        }).indexOf(header.field)
        const column = this.columns[field_id]
        column.show = header.show
        this.headers.push(column)
        tableSettings.push(column)

        if (column.footer) this.getFooterValue(column)
      })

      this.columns.forEach((column) => {

        if (!column.hasOwnProperty('show') || column.show) this.export_data.json_fields[this.$t(column.i18n) || column.headerName || column.field] = column.field


        if (column.editable) {
          this.setFirstValue(column)
        }
      })
      setTimeout(() => {
        this.firstVisit = false
      }, 1000)

    } else {
      this.headers = []
      tableSettings = []
      this.export_data.json_fields = {}

      this.columns.forEach((column) => {
        this.headers.push(column)
        tableSettings.push(column)

        if (column.footer) this.getFooterValue(column)

        if (!column.hasOwnProperty('show') || column.show) this.export_data.json_fields[this.$t(column.i18n) || column.headerName || column.field] = column.field


        if (column.editable) {
          this.setFirstValue(column)
        }
      })

      setTimeout(() => {
        this.firstVisit = false
      }, 1000)
    }

    /* store settings */
    if (this.options.id) {
      this.settings.headers.options = JSON.parse(JSON.stringify(tableSettings))
    }

    /* check header can be selectable */
    const headers_selectable = this.columns.map(elm => {
      return elm.selectable || false
    })
    this.headerSelectable = headers_selectable.indexOf(true) > -1

    /*window.addEventListener("beforeunload", () => {
      localStorage.setItem("tablesSetting", JSON.stringify(this.tablesSetting))
    })*/
  },
  mounted () {
      const sidbarElement = window.document.getElementsByClassName('side-bar-wrapper')
      if (sidbarElement.length > 0) {
        sidbarElement[0].style.width = 0
        sidbarElement[0].style.overflow = 'hidden'
        sidbarElement[0].style.opacity = 0
      }
      this.sidebarStatus.filters = false


    const el = window.document.getElementsByClassName('side-bar-wrapper')
    if (el.length > 0) {
      el[0].addEventListener('touchstart', this.handleTouchStart, {passive: false})
      el[0].addEventListener('touchend', this.handleTouchEnd, {passive: false})
    }
  },
  methods: {
    ...mapActions({
      setTableSetting: 'table/setTableSetting'
    }),
    checkLastRow (index) {
      if (this.dataList.length > 1 && this.dataList.length - 1 === index) {
        if (this.fixScreen) {
          const body = document.getElementsByClassName('root-body')[0]
          if (body && body.offsetHeight) {
            const body_height = body.offsetHeight
            const row = document.getElementsByClassName('root-body-row')[0]
            const row_height = row ? row.offsetHeight : 50

            return this.dataList.length >= (body_height / row_height)
          } else {
            return false
          }
        } else {
          return true
        }
      } else {
        return false
      }
    },
    getRowStyles (column) {
      const styles = []
      if (column.width) styles.push(`width: ${column.width}`)
      if (column.minWidth) styles.push(`min-width: ${column.minWidth}px`)

      this.columnsStyles[column.field] = `${styles.join('; ')};`
      return this.columnsStyles[column.field]
    },
    handleRowClicked (index) {
      if (this.headerSelectable && (!this.options.hasOwnProperty('rowSelectable') || this.options.rowSelectable)) {
        if (this.$refs.customDraggableDynamicTableCheckBox[index]) {
          this.$refs.customDraggableDynamicTableCheckBox[index].setChecked()
        }
      }
    },
    handleClick () {

    },
    addComma (val) {
      let sign = ''

      if (val < 0) sign = '-'

      val = val.toString()

      if (val[0] === '0') val = val.substr(1, val.length - 1)

      return val.toString().replace(/\D/g, '')
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',') + sign
    },
    printPage () {
      window.print()
    },
    setTableData () {
      this.dataList = []
      this.export_data.json_data = []
      let selectedData = []

      const encoder = new TextEncoder('utf-8')
      this.data.forEach((item) => {
        const value = {}
        Object.keys(item).forEach((key) => {
          if (typeof item[key] === 'object') {
            value[key] = item[key].value || encoder.encode(item[key].src) || ''
          } else {
            value[key] = item[key]
          }
        })

        this.dataList.push(item)
        this.export_data.json_data.push(value)

        if (!item.hasOwnProperty('show')) {
          item.show = false
        }
        selectedData.push(item)
      })

      this.headers.forEach((header) => {
        if (header.footer) this.getFooterValue(header)
      })

      this.selectedData = JSON.parse(JSON.stringify(selectedData))

      // const copyData = JSON.parse(JSON.stringify(this.dataList))
      // this.selectedData = copyData.map((elm) => {
      //   if (!elm.hasOwnProperty('show')) {
      //     elm.show = false
      //   }
      //   return elm
      // })
    },
    checkEquality (list) {
      let counter = 0
      list.forEach((header) => {

        let exist = false
        this.columns.forEach((column) => {
          column.show = header.show

          if (JSON.stringify(column) === JSON.stringify(header)) {
            exist = true
          }
        })

        if (exist) {
          counter++
        }
      })
      return counter === list.length
    },
    setSetting (activeSettings) {
      if (!this.firstVisit) {
        this.headers = []
        this.export_data.json_fields = {}
        activeSettings.headers.forEach((header) => {
          this.headers.push(header)

          if (!header.hasOwnProperty('show') || header.show) this.export_data.json_fields[this.$t(header.i18n) || header.headerName || header.field] = header.field

        })
      }
      // console.log(this.headers)
    },
    notExist (column) {
      let counter = 0
      this.headers.forEach((header) => {
        if (header.field === column.field) {
          counter++
        }
      })
      return counter === 0
    },
    getMinTableWidth () {
      this.minTableWidth = 0
      this.headers.forEach((header) => {
        if (!header.hasOwnProperty('show') || header.show) this.minTableWidth += header.minWidth
      })
    },
    sortColumn (field) {
      Object.keys(this.sortStatus).forEach((key) => {
        if (key !== field) {
          delete this.sortStatus[key]
        }
      })

      if (this.sortStatus.hasOwnProperty(field)) this.sortStatus[field] = !this.sortStatus[field]
      else this.sortStatus[field] = true

      this.$emit('sort:set', this.sortStatus)
    },
    setFirstValue (column) {
      this.dataList.forEach((item) => {
        if (!(this.cancelEditStatus[item.id] && this.cancelEditStatus[item.id][column.field])) {
          this.cancelEditStatus[item.id] = {}
          this.cancelEditStatus[item.id][column.field] = item[column.field]
        }
      })
    },
    getFooterValue (column) {
      let sum = 0
      switch (column.footer.type) {
      case 'auto-counter':
        this.footerValues[column.field] = this.dataList.length
        return this.dataList.length

      case 'auto-sum':
        this.dataList.forEach((item) => {
          if (typeof item[column.field] === 'string' || typeof item[column.field] === 'number') {
            if (parseInt(item[column.field].toString().replaceAll(',', ''))) sum += parseInt(item[column.field].toString().replaceAll(',', ''))
          } else if (typeof item[column.field] === 'object') {
            if (parseInt(item[column.field].value.toString().replaceAll(',', ''))) sum += parseInt(item[column.field].value.toString().replaceAll(',', ''))
          }
        })

        if (column.footer.textType === 'price') {
          this.footerValues[column.field] = this.addComma(sum)
          return this.addComma(sum)
        } else {
          this.footerValues[column.field] = sum
          return sum
        }
      }
    },
    getRelationValue (row, column) {
      // eslint-disable-next-line no-useless-escape
      const regex = /{[0-9]*}|([A-Za-z]+)|[\+]|[\*]|[\-]|[\/]/g
      const params = column.relation.match(regex)
      let values = 0
      let prefix = 1
      let operator = ''

      for (let i = 0; i < params.length; i++) {
        if (params[i].toString().indexOf('{') > -1 && params[i].toString().indexOf('}') > -1) {
          prefix = parseInt(params[i].toString().replace('{', '').replace('}', '')) || 1
          // eslint-disable-next-line no-useless-escape
        } else if (/\+|\-|\*|\//g.test(params[i])) {
          if (/\*/g.test(params[i])) {
            if (parseInt(row[params[i - 1]] || 0) === 0 || parseInt(row[params[i + 1]] || 0) === 0) {
              values = 0
              break
            }
          }
          operator = params[i]
        } else if (values === 0) {
          if (typeof row[params[i]] === 'number') values = prefix * row[params[i]]
          else if (typeof row[params[i]] === 'string') values = prefix * (parseInt(row[params[i]].toString().replaceAll(',', '')) || 0)
          else if (typeof row[params[i]] === 'object') values = prefix * row[params[i]].value
          prefix = 1
        } else {
          switch (operator) {
            case '+':
              if (typeof row[params[i]] === 'number') values += prefix * row[params[i]]
              else if (typeof row[params[i]] === 'string') values += prefix * (parseInt(row[params[i]].toString().replaceAll(',', '')) || 0)
              else if (typeof row[params[i]] === 'object') values += prefix * row[params[i]].value
              prefix = 1
              operator = ''
              break

            case '-':
              if (typeof row[params[i]] === 'number') values -= prefix * row[params[i]]
              else if (typeof row[params[i]] === 'string') values -= prefix * (parseInt(row[params[i]].toString().replaceAll(',', '')) || 0)
              else if (typeof row[params[i]] === 'object') values -= prefix * row[params[i]].value
              prefix = 1
              operator = ''
              break

            case '*':
              if (typeof row[params[i]] === 'number') values *= prefix * row[params[i]]
              else if (typeof row[params[i]] === 'string') values *= prefix * (parseInt(row[params[i]].toString().replaceAll(',', '')) || 0)
              else if (typeof row[params[i]] === 'object') values *= prefix * row[params[i]].value
              prefix = 1
              operator = ''
              break

            case '/':
              if (typeof row[params[i]] === 'number') values /= prefix * row[params[i]]
              else if (typeof row[params[i]] === 'string') values /= prefix * (parseInt(row[params[i]].toString().replaceAll(',', '')) || 0)
              else if (typeof row[params[i]] === 'object') values /= prefix * row[params[i]].value
              prefix = 1
              operator = ''
              break
          }
        }
      }

      switch (column.relationType) {
      case 'price':
        values = addComma(values) /*+  this.$t('currency)*/
        break
      }

      row[column.field].value = values
    },
    refactorValueType (index, id, field) {
      switch (typeof this.cancelEditStatus[id][field]) {
      case 'number':
        this.dataList[index][field] = parseInt(this.dataList[index][field].replace(/\/r/g, '/')) || 0
        break
      }
      this.$emit('input', this.dataList)
    },
    handleScroll () {

      const {clientHeight, scrollHeight, scrollTop} = document.getElementsByClassName('root-body')[0]
      // fire if the scroll position is 50 pixels above the bottom of the page
      if (scrollHeight - (clientHeight + scrollTop) < 50) {
        clearTimeout(this.handleScrollTimer)
        this.handleScrollTimer = setTimeout(() => {
          this.$emit('load:more')
        }, 400)
      }
    },
    updateInputValue (header, item) {
      this.$refs[`input_${header.field}_${item[this.options.rowKeyField]}`][0].updateFirstValue()
    },
    handleSetStatusFlag (item) {
      if (item.flag) {
        item.flag = 'UPDATED'
      }
    },
    handleCloseSuggestBox (ref) {
      if (this.$refs[ref]) {
        this.$refs[ref][0].show = false
      }
    },
    handleSuggestListSearch (header, item, item_index, first) {
      if (header.suggest) {
        this.$emit('search', {
          param: header.field,
          value: item[header.field],
          first: first || false
        })
        this.$refs[`suggestsList_${header.field}_${item_index}`][0].show = true
      }
    },
    handleSuggestSelected (data, item_index) {
      this.dataList[item_index] = data.value
      this.$emit('input', this.dataList)
      this.$emit('suggest:selected', data)
      // console.log(this.dataList[item_index])
    },
    handleSuggestPromptSelected (data, index) {
      const newData = {}
      this.columns.forEach((column) => {
        if (data.hasOwnProperty(column.field)) newData[column.field] = data[column.field]
        else newData[column.field] = ''
      })
      if (data.hasOwnProperty('id')) {
        newData.id = data.id
        const isExist = this.dataList.map((elm) => {
          return elm.id
        }).indexOf(data.id) > -1
        if (isExist) {
          this.$vs.notify({
            title: 'خطا',
            text: 'این مقدار در لیست وجود دارد',
            time: 2400,
            color: 'danger',
            icon: 'icon-alert-circle',
            iconPack: 'feather'
          })
        } else {
          this.dataList[index] = newData
        }
      } else {
        this.dataList[index] = newData
      }

      this.suggestPromptStatus = false
      this.$emit('input', this.dataList)
      this.$emit('suggest:selected', newData)
    },
    handleReloadPrompt () {
      this.suggestPromptStatus = false
      setTimeout(() => {
        this.suggestPromptStatus = true
      }, 100)
    },
    handleActionButton (header, item) {
      this.$emit(header.event ? header.event : 'clicked', item)

      switch (header.action.type) {
      case 'reset_input':
        this.updateInputValue(header, item)
        break

      case 'button':
      case 'hideAfterClick':
        break

      default:
        this.updateInputValue(header, item)
        break
      }
    },
    updateUserBrowserSettings () {
      clearTimeout(this.updateTableSettingTimer)
      this.updateTableSettingTimer = setTimeout(() => {
        this.$store.dispatch('table/updateUserBrowserSettings')
      }, 1000)
    },
    setFilters () {
      clearTimeout(this.isTypingTimer)
      this.isTypingTimer = setTimeout(() => {
        this.$emit('filter:set', this.filterStatus)
      }, 500)
    },
    handleCloseSidebar (close = true) {
      if (close) {
        this.closeActiveFilterTimer = setTimeout(() => {
          this.sidebarStatus.filters = false
        }, 1000)
      } else {
        clearTimeout(this.closeActiveFilterTimer)
      }
    },
    handleTouchStart (event) {
      const touches = event.changedTouches
      this.touchStartX = touches[0].clientX
    },
    handleTouchEnd (event) {
      const touches = event.changedTouches
      this.touchEndX = touches[0].clientX
      if ((this.touchStartX - this.touchEndX) > 50) {
        this.sidebarStatus.filters = false
      }
      // console.log(this.touchStartX - this.touchEndX)
    },
    handleClickOutsideActiveFilter (event) {
      this.sidebarStatus.filters = false
    }
  },
  watch: {
    loadMoreStatus: {
      handler () {
        /*if (val)
          setTimeout(() => {
            document.getElementsByTagName('tbody')[0].scrollTop += 150
          }, 200)*/
      },
      deep: true
    },
    activeSettings: {
      handler () {
        this.setSetting()
        this.getMinTableWidth()
      },
      deep: true
    },
    tablesSetting: {
      handler () {
        /*if (this.options.id && val[this.options.id]) {
          this.activeSettings.headers = val[this.options.id]
        }*/
      },
      deep: true
    },
    headers: {
      handler (val) {
        this.settings.headers.options = JSON.parse(JSON.stringify(val))

        if (this.options.id && !this.firstVisit) {
          this.setTableSetting({
            id: this.options.id,
            headers: val
          })

          clearTimeout(this.updateTableSettingTimer)
          this.updateTableSettingTimer = setTimeout(() => {
            this.$store.dispatch('table/updateUserBrowserSettings')
          }, 1000)
        }
        this.getMinTableWidth()

      },
      deep: true
    },
    data: {
      handler () {
        this.setTableData()
      },
      deep: true
    },
    dataList: {
      handler () {
      },
      deep: true
    },
    selectedData: {
      handler () {
        this.$emit('column:select', this.selectedData)
      },
      deep: true
    },
    filters: {
      handler () {
        const fields = [
          ...this.filters.map(elm => {
            return elm.field
          }),
          ...this.headers.map(elm => {
            return elm.field
          })
        ]
        Object.keys(this.filterStatus).forEach((field) => {
          if (fields.indexOf(field) < 0) delete this.filterStatus[field]
        })
      },
      deep: true
    },

    'sidebarStatus.filters': {
      handler (val) {
        const sidbarElement = window.document.getElementsByClassName('side-bar-wrapper')
        if (sidbarElement.length > 0) {
          if (val) {
            sidbarElement[0].style.width = '100%'
            sidbarElement[0].style.opacity = 1
            sidbarElement[0].style.overflow = 'auto'
          } else {
            sidbarElement[0].style.width = 0
            sidbarElement[0].style.overflow = 'hidden'
            sidbarElement[0].style.opacity = 0
          }
        }
      }
    }
  },
}
</script>

<style lang="scss">
.draggable-dynamic-table, .table-wrapper, .table-container, table {
  height: 100% !important;
}

.table-wrapper {
  position: relative;

  .root-wrapper {
    width: 100%;
    height: 100%;
    min-height: 200px;
    display: flex;
    flex-direction: row;
    border: 1px solid #cecece;
    border-top: 0;
    border-radius: 0 0 .5rem .5rem;
    background: #ffffff;
    overflow-y: hidden;
    overflow-x: auto;

    &.has-filters {
      width: calc(100% - 32px);
      border-radius: 0 0 0 .5rem;
    }

    &::-webkit-scrollbar {
      display: block;
      background: rgba(206, 206, 206, 0.50);
      height: 10px;
    }

    &::-webkit-scrollbar-thumb {
      display: block;
      background: rgba(206, 206, 206, 1);
      height: 6px;
      border-radius: .5rem;
    }

    &.full-border {
      border-top: 1px solid #cecece;
      border-radius: .5rem;
    }

    .root {
      position: relative;
      flex-direction: column;
      text-align: center;
      flex-grow: 1;

      .root-row {
        flex-direction: row-reverse;
        display: flex;
        line-height: 50px;

        &.last-root-row {
          border-bottom: none !important;

          .root-body-cell {
            border-bottom: none !important;
          }
        }
      }

      .root-header {
        display: flex;
        //min-width: min-content;
        flex-direction: column;

        .root-header-row {
          height: 50px;
          font-weight: 500;

          &.root-header-title {
            height: 33px;
          }

          &:last-child {
            border-bottom: 1px solid #cecece;
          }

          .root-header-cell {
            flex-grow: 1;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;


            .ordering-icon {
              margin-left: 10px;
              height: fit-content;
              display: inline-flex;
              vertical-align: middle;
            }
          }

          .root-header-filter-cell {
            flex-grow: 1;
            white-space: nowrap;
            //overflow: hidden;
            //text-overflow: ellipsis;
            padding: 0 5px;

            .filter-box {

              .filter-icon-box {
                top: 0;
              }

              .filter-dialog {
                position: absolute;
                top: 30px;
                left: 5px;
                border-radius: 0 .5rem .5rem .5rem;
                border: none;
                background: #cecece;

                li {
                  color: #FFFFFF;

                  svg {
                    font-size: 14px;
                  }
                }
              }

              .filter-suggest-box {
                margin-top: -5px;

                li {
                  border-top: 1px solid #cecece;

                  .custom-check-box {
                    display: flex;

                    .check-box-label {
                      flex-grow: 1;
                    }
                  }
                }
              }

              .useral-custom-element-select {
                height: auto !important;

                .items {
                  position: relative;
                }
              }
            }
          }
        }
      }

      .root-body {
        display: flex;
        min-height: 100px;
        //min-width: min-content;
        flex-direction: column;
        overflow-y: auto;
        overflow-x: hidden;

        &.fixed-screen {
          height: calc(100% - 133px);

          &.without-footer {
            height: calc(100% - 83px);
          }

          &.without-filters {
            height: calc(100% - 83px);

            &.without-footer {
              height: calc(100% - 33px);
            }
          }
        }

        &::-webkit-scrollbar {
          display: block;
          background: rgba(206, 206, 206, 0.50);
          width: 10px;
        }

        &::-webkit-scrollbar-thumb {
          display: block;
          background: rgba(206, 206, 206, 1);
          border-radius: .5rem;
        }

        .root-body-row {
          border-bottom: 1px solid #cecece;
          flex-direction: column;
          display: block;

          .root-row-box {
            display: flex;
            flex-direction: row;

            a {
              display: flex;
              color: #626262;
              text-decoration: none;
              min-width: 100%;

              span.ellipsis-overflow {
                display: block;
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                padding: 0 10px;

                &.price {
                  font-weight: 600;
                }

                .currency {
                  color: #888888;
                  font-size: 13px;
                  font-weight: 400;
                }
              }
            }

          }

          .root-row-children {

          }

          .root-body-cell {
            display: flex;
            justify-content: space-evenly;
            position: relative;
            flex-grow: 1;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            &.overflow-visible {
              overflow: visible;
            }

            .suggest-modal-button, .new-suggest-modal-button {
              position: absolute;
              top: 0;
              bottom: 0;
              z-index: 49000;
              margin: auto;
              //height: fit-content;
              right: 40px;
              width: 25px;
              height: 25px;
              padding: 5px;
              border: 1px solid #00bcd4;
              border-radius: .5rem;
              color: #00bcd4;
              background: white;
            }

            .new-suggest-modal-button {
              right: 10px;
              border: 1px solid #28C76F;
              color: #28C76F;
            }

            .useral-custom-element-select {

              .selected {
                border: none !important;

                &:after {
                  border-color: #cecece transparent transparent transparent;
                }
              }

              .items {
                right: -1px;
                left: -1px;
                top: 45px;
                background-color: #e5e5e5 !important;

                div {
                  line-height: 50px;

                  &:hover {
                    background-color: #cecece !important;
                  }
                }
              }
            }
          }

          &.active-grid {
            border: none;

            .root-body-cell {
              border: 1px solid #cecece;
              border-top: none;
              border-right: none;

              &:last-child {
                border-left: none;
              }
            }
          }

          .draggable-dynamic-table {

            .root-body .root-body-row .root-row-box {
              flex-direction: row-reverse;
            }

            .root-header .root-header-row,
            .root-footer .root-footer-row {
              flex-direction: row;
            }
          }
        }
      }

      .root-notifications {
        display: flex;
        flex-direction: column;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 50px;

        .root-notification-row {
          height: 50px;
          font-weight: 500;
          border-top: 1px solid #cecece;

          .root-notification-cell {
            flex-grow: 1;
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            align-items: center;
            background: #ffffff;
          }
        }
      }

      .without-footer + .root-notifications {
        bottom: 0;
      }

      .root-footer {
        display: flex;
        //min-width: min-content;
        flex-direction: column;
        background: #ffffff;

        &.fixed-footer {
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
        }

        .root-footer-row {
          height: 50px;
          font-weight: 500;

          &:first-child {
            border-top: 1px solid #cecece;
          }

          .root-footer-cell {
            flex-grow: 1;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }

    .side-bar {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      height: 100%;
      z-index: 8000;
      display: flex;
      flex-direction: row-reverse;
      box-sizing: border-box;

      .side-bar-buttons {
        width: 32px;
        height: 100%;
        padding-top: 5px;
        background: #cecece18;
        border-right: 1px solid #cecece !important;
        border-bottom: 1px solid #cecece !important;
        border-radius: 0 0 .5rem 0;

        .side-bar-button {
          width: 100%;
          line-height: 32px;
          border: none;
          background: transparent;
          padding: 5px 0;
          cursor: pointer;

          .icon-component {
            margin: 0 auto 0 8px !important;

            .icon {
              transform: rotate(-90deg);
            }
          }

          span {
            color: #626262;
            margin-top: 15px;
            writing-mode: vertical-lr;
          }
        }
      }

      .side-bar-wrapper-overflow {
        position: fixed;
        height: 100%;
        width: 100%;
        top: 0;
        right: 0;
        left: 0;
      }

      .side-bar-wrapper {
        box-shadow: 0 0 10px -2px #cecece;
        transition: all ease 0.5s;
        z-index: 100;

        &::-webkit-scrollbar {
          display: none;
        }

        &::-webkit-scrollbar-thumb {
          display: none;
        }

        .side-bar-filters {
          width: 250px;
          padding: 10px;
          height: 100%;
          background: #f4f4f4;
          border: 1px solid #cecece !important;
          border-top: none !important;
          overflow-y: auto;

          &::-webkit-scrollbar {
            display: block;
            background: rgba(206, 206, 206, 0.50);
            width: 10px;
          }

          &::-webkit-scrollbar-thumb {
            display: block;
            background: rgba(206, 206, 206, 1);
            border-radius: .5rem;
          }

          .side-bar-filter {
            flex-grow: 1;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            padding: 0 5px;
            margin-bottom: 10px;

            .side-bar-filter-label {
              font-weight: 500;
            }

            .filter-box {

              .useral-custom-element-select {
                height: auto !important;

                .items {
                  position: relative;
                }
              }
            }
          }
        }
      }
    }
  }
}


@media print {
  .draggable-dynamic-table {
    height: unset;

    .table-wrapper {
      height: unset;
      width: 100%;

      .root-wrapper {
        height: unset;
        width: 100%;

        .root {
          display: table;
          height: unset;
          width: 100%;

          .root-header {
            display: table-header-group;
            page-break-inside: avoid;
            width: 100%;
            top: 0;
            left: 0;
            right: 0;
          }
          .root-body {
            display: table;
            page-break-inside: auto;
            width: 100%;
            height: unset;

            .root-body-row {
              page-break-inside: avoid;
              page-break-after: auto;

              .root-body-cell {
              }
            }
          }

          .root-footer {
            position: relative;
            top: 0px;
          }
        }
      }
    }
  }
}
</style>

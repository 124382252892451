import store from '@/store/store'
import {getProfile} from '@/http/requests/users/users'
import {checkUserPermissions, getTimeFromServer, showLoading} from '../assets/js/functions'
import {getClubInfo} from '@/http/requests/clubInfo'
import {getAvatarUrl} from "@/assets/js/functions";
import moment from "moment-jalaali";
import Vue from 'vue'

function validateUser () {
  return getProfile()
}

function getClubInfoSetting () {
  getClubInfo().then((response) => {
    const club = response.data
    const clubInfo = {
      clubName: club.club_name,
      clubLogo: club.club_avatar ? getAvatarUrl(club.club_avatar) : ''
    }
    store.dispatch('setting/setClubInfo', clubInfo)
  })
}

export async function beforeEach (to, from, next) {
  /* in this place check user was logged in or not */

  /* enable loading when route changed */
  showLoading()
  getClubInfoSetting()

  try {
    const accessToken = localStorage.getItem('accessToken')
    if (accessToken) {
      await store.dispatch('auth/setAccessToken').then(() => {
        validateUser().then((validate) => {
          if (validate.status === 200) {
            const date = getTimeFromServer()
            store.dispatch('helper/changeCalendarDate', moment(date).format(Vue.prototype.$validator('moment.date')))

            store.dispatch('auth/updateUserInfo', validate.data.data)

            let tableSettings = JSON.parse(validate.data.data.browser_setting)
            // delete tableSettings.tables.payload
            if (tableSettings) {
              store.dispatch('table/setUserBrowserSettings', tableSettings)
            }

            if (to.meta.hasOwnProperty('permission')) {
              if (checkUserPermissions(to.meta.permission)) {
                /*if (store.state.pageHaveChanges) {
                  const answer =
                    window.confirm('شما تغییراتی دارید که ذخیره نشده است, آیا مطمئنید که می خواهید خارج شوید؟');

                  if (answer) {
                    store.dispatch('removePageChanges')
                    next();
                  } else {
                    next(false);
                  }
                } else {
                  next()
                }*/
                next()
              } else {
                next({name: 'page-error-404'})
              }
            } else {
              next()
            }
          } else {
            throw 'auth-error'
          }
        })
      })
    } else if (!to.matched.some(record => record.name === 'page-login')) {
      throw 'auth-error'
    } else {
      next()
    }
  } catch (e) {
    if (!to.matched.some(record => record.name === 'page-login')) {
      next({
        name: 'page-login'
        /* query: {redirectFrom: to.fullPath}*/
      })
      // next(false)
    } else {
      next()
    }
  }
}
